<template>
  <v-card>
    <!-- title -->
    <div class="mb-4">
      <div class="font-weight-bold mb-1">{{ answers.name }}</div>
      <div class="grey--text mb-1">{{ answers.name203n }}</div>
    </div>

    <!-- isReady -->
    <div class="mb-4">
      <div v-if="answers.isReady" class="green--text">
        <v-icon class="mr-1" color="green">mdi-check-all</v-icon>
        <span>НМИЦ оценил критерий полностью</span>
      </div>
      <div v-else class="amber--text">
        <v-icon class="mr-1" color="amber">mdi-alert</v-icon>
        <span>НМИЦ еще не оценил критерий полностью</span>
      </div>
    </div>

    <!-- answers -->
    <div class="mb-1 font-weight-bold">Ответы:</div>
    <div class="d-flex mb-2">
      <v-chip outlined large class="mr-2" v-if="answers.criteriaNecessity"
        >Необходимость: {{ answers.criteriaNecessity }}</v-chip
      >
      <v-chip outlined large class="mr-2" v-if="answers.criteriaAccessability"
        >Доступность: {{ answers.criteriaAccessability }}</v-chip
      >
      <v-chip outlined large class="mr-2" v-if="answers.criteriaExpediency"
        >Целесообразность: {{ answers.criteriaExpediency }}</v-chip
      >
    </div>

    <!-- comments -->
    <div v-if="hasAnyComment">
      <div class="mb-1">
        <span class="tip" @click="switchComment">{{ buttonText }}</span>
      </div>
      <div v-if="showComment">
        <TitleAndDataLine
          v-if="necessityComment"
          label="Необходимость"
          :value="necessityComment"
        />

        <TitleAndDataLine
          v-if="accessabilityComment"
          label="Доступность"
          :value="accessabilityComment"
        />
        <TitleAndDataLine
          v-if="expediencyComment"
          label="Целесообразность"
          :value="expediencyComment"
        />
      </div>
    </div>
    <div v-else class="grey--text">Комментариев нет</div>
  </v-card>
</template>

<script>
import { isNMICAnswersReady } from '@/lib/answers'
import TitleAndDataLine from '@/components/common/TitleAndDataLine.vue'

export default {
  props: ['rawOneCriteriaAnswers'],
  components: { TitleAndDataLine },
  data() {
    return {
      showComment: false,
    }
  },
  methods: {
    switchComment() {
      this.showComment = !this.showComment
    },
  },
  computed: {
    necessityComment() {
      return this.rawOneCriteriaAnswers.necessityComment
    },
    accessabilityComment() {
      return this.rawOneCriteriaAnswers.accessabilityComment
    },
    expediencyComment() {
      return this.rawOneCriteriaAnswers.expediencyComment
    },
    hasAnyComment() {
      return (
        this.necessityComment ||
        this.accessabilityComment ||
        this.expediencyComment
      )
    },
    hasExactlyOneComment() {
      return (
        Boolean(this.necessityComment) +
          Boolean(this.accessabilityComment) +
          Boolean(this.expediencyComment) ===
        1
      )
    },
    answers() {
      return {
        ...this.rawOneCriteriaAnswers,
        isReady: isNMICAnswersReady(this.rawOneCriteriaAnswers),
      }
    },
    buttonText() {
      if (this.showComment) return 'Свернуть'
      return this.hasExactlyOneComment
        ? 'Показать комментарий'
        : 'Показать комментарии'
    },
  },
}
</script>

<style scoped>
.tip {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  cursor: pointer;
  transition: opacity 0.2s ease;
}
.tip:hover {
  opacity: 0.4;
}
</style>
