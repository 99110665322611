<template>
  <div>
    <PageHeader text="Предварительный просмотр ответов" />

    <div class="mb-6">
      <TitleAndDataLine
        label="Заболевание (состояние)"
        :value="nosologiaName"
      />
      <TitleAndDataLine label="ГВС" :value="gvsFIO" />
      <TitleAndDataLine label="Состояние анкеты" :value="answersState" />
      <v-btn
        class="mt-2"
        v-if="isCompleted"
        color="primary"
        @click="onCancelGVSAnswers"
        >Вернуть на доработку</v-btn
      >
    </div>

    <ShowGVSAnswersCard
      v-for="criteriaAnswers in nosologiaAnswers"
      :key="criteriaAnswers.id"
      class="mb-2 pa-4"
      :rawOneCriteriaAnswers="criteriaAnswers"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader.vue'
import TitleAndDataLine from '@/components/common/TitleAndDataLine.vue'
import ShowGVSAnswersCard from '@/components/project/stages/gvs/ShowGVSAnswersCard.vue'

import { cancelGVSAnswers } from '@/lib/requestsAPI'
import { sendDataWithToastNoRedirect } from '@/mixins/sendDataWithToastNORedirect'

export default {
  props: ['userProjectNosologia'],
  components: { PageHeader, TitleAndDataLine, ShowGVSAnswersCard },
  mixins: [sendDataWithToastNoRedirect],
  methods: {
    onCancelGVSAnswers() {
      this.sendDataWithToastNoRedirect({
        action: cancelGVSAnswers,
        data: this.userProjectNosologiaId,
        successMessage: 'Отменили ответы ГВС',
        errorMessage: 'Ошибка отмены ответов',
        onSuccess: () => this.$router.go(),
      })
    },
  },
  computed: {
    isCompleted() {
      return this.userProjectNosologia.is_completed
    },
    answersState() {
      const { answered_criteria_count, common_criteria_count } =
        this.userProjectNosologia
      if (this.isCompleted) return 'Готова и отправлена'
      if (answered_criteria_count / common_criteria_count === 1)
        return 'ГВС ответил на все вопросы, но еще не отправил анкету'
      return 'ГВС еще не ответил на все вопросы'
    },
    nosologiaName() {
      return this.userProjectNosologia.projectNosology.nosology.name
    },
    gvsFIO() {
      return this.userProjectNosologia.user.fio
    },
    nosologiaAnswers() {
      return this.userProjectNosologia.userCriterionStages.map(ucs => ({
        ...ucs.data,
        id: ucs.criteria_stage_id,
        name: ucs.criterionStage.criterion.name,
        name203n: ucs.criterionStage.criterion.name_203n,
      }))
    },
  },
}
</script>
