<template>
  <v-card>
    <!-- title -->
    <div class="mb-4">
      <div class="font-weight-bold mb-1">{{ answers.name }}</div>
      <div class="grey--text mb-1">{{ answers.name203n }}</div>
    </div>

    <!-- isReady & isPass -->
    <div class="mb-4">
      <div v-if="answers.isReady && answers.isPass" class="green--text">
        <v-icon class="mr-1" color="green">mdi-check-all</v-icon>
        <span
          >ГВС оценил критерий полностью: Критерий пройдет на следующий
          этап</span
        >
      </div>
      <div v-else-if="answers.isReady && !answers.isPass">
        <v-icon class="mr-1">mdi-cancel</v-icon>
        <span
          >Критерий не пройдет на следующий этап. ГВС оценил критерий
          полностью</span
        >
      </div>
      <div v-else class="amber--text">
        <v-icon class="mr-1" color="amber">mdi-alert</v-icon>
        <span>ГВС еще не оценил критерий полностью</span>
      </div>
    </div>

    <!-- answers -->
    <div class="mb-1 font-weight-bold">Ответы:</div>
    <div class="d-flex mb-2">
      <v-chip outlined large class="mr-2" v-if="answers.isStationar"
        >Специализированная</v-chip
      >
      <v-chip outlined large class="mr-2" v-if="answers.isAmbulatory"
        >Первичная</v-chip
      >
      <v-chip outlined large class="mr-2" v-if="answers.readableStatus">{{
        answers.readableStatus
      }}</v-chip>
      <v-chip outlined large class="mr-2" v-if="answers.criteriaNecessity"
        >Необходимость: {{ answers.criteriaNecessity }}</v-chip
      >
    </div>

    <!-- comments -->
    <div v-if="hasAnyComment">
      <div class="mb-1">
        <span class="tip" @click="switchComment">{{ buttonText }}</span>
      </div>
      <div v-if="showComment">
        <TitleAndDataLine
          v-if="typeComment"
          label="Вид помощи"
          :value="typeComment"
        />

        <TitleAndDataLine
          v-if="statusComment"
          label="Общий или этап"
          :value="statusComment"
        />
        <TitleAndDataLine
          v-if="necessityComment"
          label="Необходимость"
          :value="necessityComment"
        />
      </div>
    </div>
    <div v-else class="grey--text">Комментариев нет</div>
  </v-card>
</template>

<script>
import { isAnswerPassFromGVS, isGVSAnswersReady } from '@/lib/answers'
import TitleAndDataLine from '@/components/common/TitleAndDataLine.vue'
import { CRITERIA_STATUS } from '@/store/const/criteriaStatus'

export default {
  props: ['rawOneCriteriaAnswers'],
  components: { TitleAndDataLine },
  data() {
    return {
      showComment: false,
    }
  },
  methods: {
    switchComment() {
      this.showComment = !this.showComment
    },
  },
  computed: {
    necessityComment() {
      return this.rawOneCriteriaAnswers.necessityComment
    },
    statusComment() {
      return this.rawOneCriteriaAnswers.statusComment
    },
    typeComment() {
      return this.rawOneCriteriaAnswers.typeComment
    },
    hasAnyComment() {
      const { necessityComment, statusComment, typeComment } = this
      return necessityComment || statusComment || typeComment
    },
    hasExactlyOneComment() {
      const { necessityComment, statusComment, typeComment } = this
      return (
        Boolean(necessityComment) +
          Boolean(statusComment) +
          Boolean(typeComment) ===
        1
      )
    },
    answers() {
      const { criteriaStatus } = this.rawOneCriteriaAnswers
      return {
        ...this.rawOneCriteriaAnswers,
        isPass: isAnswerPassFromGVS(this.rawOneCriteriaAnswers),
        isReady: isGVSAnswersReady(this.rawOneCriteriaAnswers),
        readableStatus: criteriaStatus
          ? CRITERIA_STATUS[criteriaStatus].name
          : '',
      }
    },
    buttonText() {
      if (this.showComment) return 'Свернуть'
      return this.hasExactlyOneComment
        ? 'Показать комментарий'
        : 'Показать комментарии'
    },
  },
}
</script>

<style scoped>
.tip {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  cursor: pointer;
  transition: opacity 0.2s ease;
}
.tip:hover {
  opacity: 0.4;
}
</style>
