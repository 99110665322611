<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <component
        :is="answerComponentName"
        :userProjectNosologia="userProjectNosologia"
      ></component>
    </div>
    <div v-else>Нет проекта</div>
  </DisplayLoading>
</template>

<script>
import DisplayLoading from '@/components/common/DisplayLoading.vue'
import PreviewGVSNosologiaAnswers from '@/components/project/stages/gvs/PreviewGVSNosologiaAnswers.vue'
import PreviewNMICNosologiaAnswers from '@/components/project/stages/nmic/PreviewNMICNosologiaAnswers.vue'

import { routeParams } from '@/mixins/routeParams'
import { loadPageData } from '@/mixins/loadPageData'

import { PROJECT_STAGES } from '@/store/const/projectStages'

export default {
  data() {
    return {
      loadingMessage: 'Загружаю проект',
    }
  },
  components: {
    DisplayLoading,
    PreviewGVSNosologiaAnswers,
    PreviewNMICNosologiaAnswers,
  },
  mixins: [routeParams, loadPageData],
  computed: {
    loadUrl() {
      return `users-projects-nosologies/${this.userProjectNosologiaId}`
    },
    errorMessage() {
      return `Не удалось загрузить ответы для проекта: ${this.projectId}`
    },
    userProjectNosologia() {
      return this.pageData
    },
    stageId() {
      return this.userProjectNosologia.projectNosology.project.stage_id
    },
    answerComponentName() {
      // Если у проекта нет заболеваний его stage — null.
      // В этом случае считаем что его stage = 1 (черновик)
      return PROJECT_STAGES[this.stageId || 1].answerComponentName
    },
  },
}
</script>
